import { getCurrentStep, trackFormStepGA, hideAllSteps, updateTabIndexes, trackFormStepChangeDataLayer, getConsentListing, rerunConsentSearchesOnInputChange } from '../../../helpers';
import { isCurrentStepSecondToLast } from '../../../validators';

/**
 * Show step DOM element with specified ID.
 * @param {String} stepId - ID of DOM element for the step. Example: "step-1"
 */
export function showStep(stepId) {
	const oldStepId = getCurrentStep().getAttribute('id');

	// Fire callback before switching steps
	if (window.modForm.opts.stepChangingCallback && 'function' === typeof window.modForm.opts.stepChangingCallback) {
		stepId = window.modForm.opts.stepChangingCallback(oldStepId, stepId);
	}

	if (!stepId) {
		return;
	}

	// Track step switching in GA
	trackFormStepGA(stepId);

	// Hide all steps and show specified
	hideAllSteps();
	window.modForm.opts.form.querySelector('#' + stepId).classList.add(window.modForm.opts.stepActiveCls);
	if (window.modForm.opts.addAccessibilityUpdates) {
		window.modForm.opts.form.querySelector('#' + stepId).setAttribute('aria-hidden', 'false');
	}

	if (window.modForm.opts.isVite && window.modForm.opts.scrollTopOnStepChange) {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	const activeStepIndex = parseInt(stepId.slice(4));

	// Fire callback after switching steps
	if (window.modForm.opts.stepChangedCallback && 'function' === typeof window.modForm.opts.stepChangedCallback) {
		window.modForm.opts.stepChangedCallback(oldStepId, stepId);
	}

	if (window.modForm.opts.vertical && window.modForm.opts.fetchAndFormatConsentListings && (activeStepIndex === window.modForm.opts.steps.length - 1)) {
		getConsentListing(window.modForm.opts.getConsentListingCallback);
	}

	if (window.modForm.opts.fetchAndFormatConsentListings && window.modForm.opts.rerunConsentSearchesOnInputChange && isCurrentStepSecondToLast()) {
		rerunConsentSearchesOnInputChange();
	}

	if (window.modForm.opts.gettingLiveConsent && window.modForm.isCurrentStepLast() && !window.modForm.opts.form.querySelector('.consent-capture')) {
		window.Modalytics.heap('track', ['Consent Searches | Not Available on Last Step']);
	}

	if (window.innerWidth < 768) {
		updateTabIndexes();
	}

	// track steps in dataLayer
	trackFormStepChangeDataLayer();
}
